import React from "react";
import { graphql } from "gatsby";
import { AllStoryblokEntryResult, storyType } from "../types";
import Layout from "../layouts/layout";

import DynamicComponent from "../components";

import useStoryblok from "../lib/storyblok";

interface NotFoundPageProps {
  data: AllStoryblokEntryResult;
  location: Location;
}

function parseQueryResult(data: AllStoryblokEntryResult): {
  story?: storyType;
  settings?: storyType;
} {
  let story, settings;

  data.allStoryblokEntry.edges.forEach((edge) => {
    const { node } = edge;
    let content = node.content;
    if (typeof node.content === "string") content = JSON.parse(node.content);

    if (node.full_slug === "404") {
      story = node;
      story.content = content;
    } else if (node.full_slug === "en/settings") {
      settings = node;
      settings.content = content;
    }
  });

  return { story, settings };
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data, location }) => {
  let { story, settings } = useStoryblok(location);
  if (!story) ({ story, settings } = parseQueryResult(data));

  if (story)
    return (
      <Layout settings={settings}>
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          story={story}
          blok={story.content}
          path={`/${story.full_slug}/`}
        />
      </Layout>
    );

  return null;
};

export default NotFoundPage;

export const query = graphql`
  query {
    allStoryblokEntry(filter: { slug: { in: ["404", "settings"] } }) {
      edges {
        node {
          id
          name
          created_at
          uuid
          slug
          full_slug
          content
          is_startpage

          group_id
        }
      }
    }
  }
`;
